import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  FooterComponent,
  HeaderComponent,
  SideNavLayoutComponent,
  SideMenuComponent,
  MenuItem,
} from '@web/layout';
import { menuItems, userMenuItems } from './app.navigation';
import { LoginComponent } from './components/login/login.component';
import { AuthService } from '@core/auth';
import notify from 'devextreme/ui/notify';
import { distinctUntilChanged, finalize } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SideNavLayoutComponent,
    FooterComponent,
    SideMenuComponent,
    LoginComponent,
    HeaderComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  public menuItems = menuItems;
  public userMenuItems = userMenuItems;
  public menuOpen = true;

  constructor(
    public readonly authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.isAuthenticated$
      .pipe(distinctUntilChanged())
      .subscribe((isAuthenticated) => (this.menuOpen = isAuthenticated));
  }

  public login({ email, password }: { email: string; password: string }) {
    this.authService.login(email, password).subscribe({
      error: (e) => {
        notify(e.error.message, 'error');
      },
    });
  }

  public menuItemClicked({ data }: MenuItem) {
    if (data && data.logout) {
      this.authService
        .logout()
        .pipe(finalize(() => this.router.navigateByUrl('/login')))
        .subscribe({
          error: (error) => notify(error.message, 'error'),
        });
    }
    // möglich das menü zu schließen etc
    // else {
    //   this.menuOpen = false;
    // }
  }
}
