<web-layout-side-nav-layout
  *ngIf="{ isAuth: authService.isAuthenticated$ | async } as user"
  menuPosition="right"
  [menuToggleEnabled]=" !!user.isAuth"
  [(menuOpened)]="menuOpen"
>
  <div class="w3-bar w3-container w3-panel" slot="header">
    <img class="header-logo" src="logo.svg" />
  </div>

  <web-layout-side-menu
    slot="side"
    [menuItems]="menuItems"
    [userMenuItems]="userMenuItems"
    (menuItemClicked)="menuItemClicked($event)"
  >
  </web-layout-side-menu>

  <router-outlet></router-outlet>

  <web-layout-footer fixed="bottom" slot="footer">
    <div class="copyright">© 2024 Riegermann Gutachten & Auktionen</div>
    <a href="https://www.riegermann.de"
      ><img src="20220419_Riegermann_URL_CMYK.png" height="9px"
    /></a>
    <div>Helpdesk Telefon 06704/93920</div>
  </web-layout-footer>
</web-layout-side-nav-layout>
<!--

<ng-template #unauthenticated>
  <web-layout-header>
    <div class="w3-bar w3-container w3-panel" slot="header">
      <img class="header-logo" src="logo.svg" />
    </div>
  </web-layout-header>

  <router-outlet></router-outlet>

  <web-layout-footer fixed="bottom" slot="footer">
    © 2024 Riegermann Ingenieur & Handelscontor GmbH
  </web-layout-footer>
</ng-template> -->
