import { MenuItem } from '@web/layout';

export const menuItems: MenuItem[] = [
  {
    label: 'common.projectList',
    path: '/projects',
  },
  {
    label: 'common.client',
    path: '/clients',
  },
  {
    label: 'common.userList',
    path: '/users',
  },
  {
    label: 'common.accessCodes',
    path: '/access-codes',
  },
];

export const userMenuItems: MenuItem[] = [
  {
    label: 'common.changePassword',
    path: '/change-password',
  },
  {
    label: 'common.logout',
    path: null,
    data: { logout: true },
  },
];
