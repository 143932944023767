import { Environment } from './interface';

export const environment: Environment = {
  production: false,
  apiBase: 'https://riegermann-api.fsf.tools',
  oauth: {
    client_id: '1',
    client_secret: 'WKE5xFPCkdGO3PAGcRgBUEvt9YuXw1dDWgQoCraz',
  },
};
