import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { TranslateHttpLoader } from '@codeandweb/http-loader';
import { TranslateLoader, TranslateModule } from '@codeandweb/ngx-translate';
import { API_BASE } from '@core/api';
import { authenticationInterceptor, OAUTH_CONFIG } from '@core/auth';
import { environment } from '@shared/environment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { appRoutes } from './app.routes';

import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import deMessages from 'devextreme/localization/messages/de.json';
import { locale, loadMessages } from 'devextreme/localization';
import config from 'devextreme/core/config';

registerLocaleData(localeDe);
loadMessages(deMessages);
locale('de');
config({ defaultCurrency: 'EUR' });

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (
  http: HttpClient
) => new TranslateHttpLoader(http, './i18n/', '.json');

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptors([authenticationInterceptor])),
    // provideHttpClient(),
    importProvidersFrom(NgxPermissionsModule.forRoot()),
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: 'de',
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ]),
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: API_BASE, useValue: environment.apiBase },
    { provide: OAUTH_CONFIG, useValue: environment.oauth },
    // {
    //   provide: NOTIFICATION_DURATION,
    //   useValue: { success: 3000, warning: 3000, error: 3000 },
    // },
    // { provide: APP_PERMISSIONS, useValue: appPermissions },
    // { provide: APP_ADMIN_PERMISSIONS, useValue: appAdminPermissions },
    provideRouter(appRoutes),
  ],
};
