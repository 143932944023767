import { Route } from '@angular/router';
import { authGuard } from '@core/auth';

export const appRoutes: Route[] = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login-page/login-page.component').then(
        (c) => c.LoginPageComponent
      ),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./pages/forgot-password/forgot-password.component').then(
        (c) => c.ForgotPasswordComponent
      ),
  },
  {
    path: 'reset-password/:hash',
    loadComponent: () =>
      import('./pages/reset-password/reset-password.component').then(
        (c) => c.ResetPasswordComponent
      ),
  },
  {
    path: 'change-password',
    loadComponent: () =>
      import('./pages/reset-password/reset-password.component').then(
        (c) => c.ResetPasswordComponent
      ),
  },
  {
    path: 'set-password/:hash',
    loadComponent: () =>
      import('./pages/reset-password/reset-password.component').then(
        (c) => c.ResetPasswordComponent
      ),
  },
  {
    path: '',
    canActivate: [authGuard],
    data: {
      redirectTo: '/login',
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'projects' },
      {
        path: 'project',
        children: [
          {
            path: 'create',
            loadComponent: () =>
              import('./pages/project-detail/project-detail.component').then(
                (c) => c.ProjectDetailComponent
              ),
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/project-detail/project-detail.component').then(
                (c) => c.ProjectDetailComponent
              ),
          },
        ],
      },
      {
        path: 'projects',
        loadComponent: () =>
          import('./pages/project-list/project-list.component').then(
            (c) => c.ProjectListComponent
          ),
      },
      {
        path: 'project/:project-id/thirdparty-right',
        children: [
          {
            path: 'create',
            loadComponent: () =>
              import(
                './pages/thirdparty-right-detail/thirdparty-right-detail.component'
              ).then((c) => c.ThirdpartyRightDetailComponent),
          },
          {
            path: ':id',
            loadComponent: () =>
              import(
                './pages/thirdparty-right-detail/thirdparty-right-detail.component'
              ).then((c) => c.ThirdpartyRightDetailComponent),
          },
        ],
      },
      {
        path: 'project/:project-id/thirdparty-rights',
        loadComponent: () =>
          import(
            './pages/thirdparty-right-list/thirdparty-right-list.component'
          ).then((c) => c.ThirdpartyRightListComponent),
      },
      {
        path: 'users',
        loadComponent: () =>
          import('./pages/user-management/user-management.component').then(
            (c) => c.UserManagementComponent
          ),
      },
      {
        path: 'user',
        children: [
          {
            path: 'create',
            loadComponent: () =>
              import('./pages/user-detail/user-detail.component').then(
                (c) => c.UserDetailComponent
              ),
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/user-detail/user-detail.component').then(
                (c) => c.UserDetailComponent
              ),
          },
        ],
      },
      {
        path: 'client',
        children: [
          {
            path: 'create',
            loadComponent: () =>
              import('./pages/client-details/client-details.component').then(
                (c) => c.ClientDetailsComponent
              ),
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/client-details/client-details.component').then(
                (c) => c.ClientDetailsComponent
              ),
          },
        ],
      },
      {
        path: 'clients',
        loadComponent: () =>
          import('./pages/client-list/client-list.component').then(
            (c) => c.ClientListComponent
          ),
      },
      {
        path: 'access-codes',
        loadComponent: () =>
          import('./pages/access-code-list/access-code-list.component').then(
            (c) => c.AccessCodeListComponent
          ),
      },
      {
        path: 'project/:project-id/inventory-list',
        loadComponent: () =>
          import('./pages/inventory-list/inventory-list.component').then(
            (c) => c.InventoryListComponent
          ),
      },
      {
        path: 'view/:uid',
        loadComponent: () =>
          import('./pages/inventory-view/inventory-view.component').then(
            (c) => c.InventoryViewComponent
          ),
      },
      {
        path: 'project/:project-id/inventory',
        children: [
          {
            path: 'create',
            loadComponent: () =>
              import(
                './pages/inventory-detail/inventory-detail.component'
              ).then((c) => c.InventoryDetailComponent),
          },
          {
            path: ':id',
            loadComponent: () =>
              import(
                './pages/inventory-detail/inventory-detail.component'
              ).then((c) => c.InventoryDetailComponent),
          },
          {
            path: ':id/view',
            loadComponent: () =>
              import('./pages/inventory-view/inventory-view.component').then(
                (c) => c.InventoryViewComponent
              ),
          },
        ],
      },
    ],
  },
];
